import type { LocaleObject } from "@nuxtjs/i18n";

export default function setCanonicalUrl(url?: string) {
    if (!url) return;

    const { public: config } = useRuntimeConfig();
    const nuxtApp = useNuxtApp();
    const { locales } = nuxtApp.$i18n;

    // The hid values must match the hid from Nuxt i18n so we can override it
    // see: https://github.com/nuxt-modules/i18n/blob/main/src/runtime/routing/head.ts
    const localeMap = new Map<string, LocaleObject>();
    for (const locale of locales.value) {
        const [language, region] = locale.code.split("-");
        if (language && region && (locale.isCatchallLocale || !localeMap.has(language))) {
            localeMap.set(language, locale);
        }

        localeMap.set(locale.code, locale);
    }

    const link: { hid: string; rel: string; href: string; hreflang?: string }[] = [
        { hid: "i18n-can", rel: "canonical", href: `${config.baseUrl}${url}` },
    ];

    const path = url.substring(url.indexOf("/", 1) + 1); // remainder of path without current locale code

    for (const [language, mapLocale] of localeMap.entries()) {
        const href = `${config.baseUrl}/${mapLocale.code}/${path}`;
        link.push({ hid: `i18n-alt-${language}`, href, rel: "alternate", hreflang: language });
    }

    useHead({ link });
}
