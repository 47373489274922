import validate from "/src/Frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import apm_45global from "/src/Frontend/middleware/apm.global.ts";
import canonical_45global from "/src/Frontend/middleware/canonical.global.ts";
import manifest_45route_45rule from "/src/Frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  apm_45global,
  canonical_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}