export default function useInitializeState() {
    const { locale } = useI18n();
    const isAuthenticated = useStateIsAuthenticated();
    const userInfo = useStateUserInfo();
    const locationInfo = useStateLocationInfo();
    const regionInfo = useStateRegionInfo();
    const currencyInfo = useStateCurrencyInfo();
    const globalConfig = useStateGlobalConfig();
    const config = useRuntimeConfig();
    const { cdnUrl } = config.public;

    // ASP.NET Core makes use of 2 tokens for XSRF prevention
    // the persistent cookie token "eciarvt" and the request token "XSRF-TOKEN"
    const eciarvtCookie = useCookie("eciarvt");
    const xsrfCookie = useCookie("XSRF-TOKEN");
    const xsrfToken = useState("xsrfToken", () => xsrfCookie);

    return async function initializeState() {
        const { data: accountData } = await useFetchApi<AccountData>("api/identity/info", {
            query: { lang: locale.value },
        });

        if (accountData.value?.XSRFCookieToken) {
            eciarvtCookie.value = accountData.value.XSRFCookieToken;
        }
        xsrfToken.value = accountData.value?.XSRFRequestToken;
        isAuthenticated.value = accountData.value?.IsAuthenticated || false;
        userInfo.value = accountData.value?.User || { Roles: [] };
        locationInfo.value = accountData.value?.Location || {
            CountryCode: "US",
            CurrencyCode: "USD",
            RegionId: 1,
            HomeRegionId: 1,
        };
        globalConfig.value = {
            isAuthenticated: isAuthenticated.value,
            CDNUrl: cdnUrl,
            CurrencyCode: locationInfo.value.CurrencyCode || "USD",
            LanguageCode: locale.value,
            Features: accountData.value?.Features || EMPTY_FEATURE_FLAGS,
            SearchText: "",
        };
        regionInfo.value = accountData.value?.Regions || [];
        currencyInfo.value = accountData.value?.Currencies || [];

        return { isAuthenticated, userInfo, locationInfo, regionInfo, currencyInfo, globalConfig, accountData };
    };
}
