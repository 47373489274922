
// @ts-nocheck


export const localeCodes =  [
  "en",
  "de",
  "es",
  "fr",
  "it",
  "ja",
  "pt",
  "zh-Hans",
  "zh-Hant"
]

export const localeLoaders = {
  "en": [{ key: "../i18n/locales/en.json", load: () => import("../i18n/locales/en.json" /* webpackChunkName: "locale__src_Frontend_i18n_locales_en_json" */), cache: true }],
  "de": [{ key: "../i18n/locales/de.json", load: () => import("../i18n/locales/de.json" /* webpackChunkName: "locale__src_Frontend_i18n_locales_de_json" */), cache: true }],
  "es": [{ key: "../i18n/locales/es.json", load: () => import("../i18n/locales/es.json" /* webpackChunkName: "locale__src_Frontend_i18n_locales_es_json" */), cache: true }],
  "fr": [{ key: "../i18n/locales/fr.json", load: () => import("../i18n/locales/fr.json" /* webpackChunkName: "locale__src_Frontend_i18n_locales_fr_json" */), cache: true }],
  "it": [{ key: "../i18n/locales/it.json", load: () => import("../i18n/locales/it.json" /* webpackChunkName: "locale__src_Frontend_i18n_locales_it_json" */), cache: true }],
  "ja": [{ key: "../i18n/locales/ja.json", load: () => import("../i18n/locales/ja.json" /* webpackChunkName: "locale__src_Frontend_i18n_locales_ja_json" */), cache: true }],
  "pt": [{ key: "../i18n/locales/pt.json", load: () => import("../i18n/locales/pt.json" /* webpackChunkName: "locale__src_Frontend_i18n_locales_pt_json" */), cache: true }],
  "zh-Hans": [{ key: "../i18n/locales/zh-hans.json", load: () => import("../i18n/locales/zh-hans.json" /* webpackChunkName: "locale__src_Frontend_i18n_locales_zh_hans_json" */), cache: true }],
  "zh-Hant": [{ key: "../i18n/locales/zh-hant.json", load: () => import("../i18n/locales/zh-hant.json" /* webpackChunkName: "locale__src_Frontend_i18n_locales_zh_hant_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n/i18n.config.ts?hash=5b32d2e0&config=1" /* webpackChunkName: "__i18n_config_ts_5b32d2e0" */)
]

export const nuxtI18nOptions = {
  "restructureDir": "i18n",
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "typedPages": true,
    "typedOptionsAndMessages": false,
    "generatedLocaleFilePathFormat": "absolute",
    "alternateLinkCanonicalQueries": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false,
    "optimizeTranslationDirective": true
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "language": "en",
      "files": [
        "/src/Frontend/i18n/locales/en.json"
      ]
    },
    {
      "code": "de",
      "name": "Deutsch",
      "language": "de",
      "files": [
        "/src/Frontend/i18n/locales/de.json"
      ]
    },
    {
      "code": "es",
      "name": "Español",
      "language": "es",
      "files": [
        "/src/Frontend/i18n/locales/es.json"
      ]
    },
    {
      "code": "fr",
      "name": "Français",
      "language": "fr",
      "files": [
        "/src/Frontend/i18n/locales/fr.json"
      ]
    },
    {
      "code": "it",
      "name": "Italiano",
      "language": "it",
      "files": [
        "/src/Frontend/i18n/locales/it.json"
      ]
    },
    {
      "code": "ja",
      "name": "日本語",
      "language": "ja",
      "files": [
        "/src/Frontend/i18n/locales/ja.json"
      ]
    },
    {
      "code": "pt",
      "name": "Português",
      "language": "pt",
      "files": [
        "/src/Frontend/i18n/locales/pt.json"
      ]
    },
    {
      "code": "zh-Hans",
      "name": "简体中文",
      "language": "zh-Hans",
      "files": [
        "/src/Frontend/i18n/locales/zh-hans.json"
      ]
    },
    {
      "code": "zh-Hant",
      "name": "繁體中文",
      "language": "zh-Hant",
      "files": [
        "/src/Frontend/i18n/locales/zh-hant.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "ecialn",
    "cookieSecure": false,
    "fallbackLocale": "en",
    "redirectOn": "no prefix",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "language": "en",
    "files": [
      {
        "path": "/src/Frontend/i18n/locales/en.json"
      }
    ]
  },
  {
    "code": "de",
    "name": "Deutsch",
    "language": "de",
    "files": [
      {
        "path": "/src/Frontend/i18n/locales/de.json"
      }
    ]
  },
  {
    "code": "es",
    "name": "Español",
    "language": "es",
    "files": [
      {
        "path": "/src/Frontend/i18n/locales/es.json"
      }
    ]
  },
  {
    "code": "fr",
    "name": "Français",
    "language": "fr",
    "files": [
      {
        "path": "/src/Frontend/i18n/locales/fr.json"
      }
    ]
  },
  {
    "code": "it",
    "name": "Italiano",
    "language": "it",
    "files": [
      {
        "path": "/src/Frontend/i18n/locales/it.json"
      }
    ]
  },
  {
    "code": "ja",
    "name": "日本語",
    "language": "ja",
    "files": [
      {
        "path": "/src/Frontend/i18n/locales/ja.json"
      }
    ]
  },
  {
    "code": "pt",
    "name": "Português",
    "language": "pt",
    "files": [
      {
        "path": "/src/Frontend/i18n/locales/pt.json"
      }
    ]
  },
  {
    "code": "zh-Hans",
    "name": "简体中文",
    "language": "zh-Hans",
    "files": [
      {
        "path": "/src/Frontend/i18n/locales/zh-hans.json"
      }
    ]
  },
  {
    "code": "zh-Hant",
    "name": "繁體中文",
    "language": "zh-Hant",
    "files": [
      {
        "path": "/src/Frontend/i18n/locales/zh-hant.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
