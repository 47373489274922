// keep up to date with Libraries/Core/Services/FeatureService.cs
export const EMPTY_FEATURE_FLAGS = {
    SMART_SEARCH: false,
    SEARCH_PROGRESSIVE_DELIVERY: false,
    SMART_SEARCH_QUERY_PARSING: false,
    FAQ_STATIC_SITE: false,
    PRODUCT_SPOTLIGHTS: false,
    DATA_DOME_BOT_HEADERS: false,
    CN_FILEFEED_RESULTS: false,
    LEAD_TIME_REPORTING_PUBLIC: false,
    PART_CHART_FAILED_QUERY_LOGGING: false,
    TAILWIND_COMPONENTS_PAGE: false,
    RESULTS_BY_PART_DEFAULT: false,
    PART_MODELS_PAGE: false,
    SLUICEBOX_INTEGRATION: false,
    SNAP_MAGIC_MODELS: false,
    ALL_CATEGORIES_PARAMETRIC_SEARCH: false,
};

export type FeatureFlags = typeof EMPTY_FEATURE_FLAGS;
