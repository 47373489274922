import revive_payload_client_4sVQNw7RlN from "/src/Frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/src/Frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/src/Frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/src/Frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/src/Frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/src/Frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/src/Frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/src/Frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/src/Frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_vvOX7kxpKw from "/src/Frontend/node_modules/nuxt-rollbar/dist/runtime/plugin.mjs";
import plugin_client_UYiXMU8ZyN from "/src/Frontend/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_client_4F1kZh3YAB from "/src/Frontend/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/src/Frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_VrpP1JaYg8 from "/src/Frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_sq1MuCrqbC from "/src/Frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import hidden_client_mqaG3M4UV0 from "/src/Frontend/plugins/hidden.client.ts";
import ssg_detect_3fHkBxLtv0 from "/src/Frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_vvOX7kxpKw,
  plugin_client_UYiXMU8ZyN,
  plugin_client_4F1kZh3YAB,
  switch_locale_path_ssr_5csfIgkrBP,
  route_locale_detect_VrpP1JaYg8,
  i18n_sq1MuCrqbC,
  hidden_client_mqaG3M4UV0,
  ssg_detect_3fHkBxLtv0
]