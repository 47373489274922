export default defineNuxtRouteMiddleware((to) => {
    // skip middleware on client side
    if (import.meta.client) return;

    const nuxtApp = useNuxtApp();
    const [routeName] = String(to.name).split("___"); // i18n appends locale like '___en' to route name

    try {
        nuxtApp.$apm.setTransactionName(routeName);
    } catch (err) {
        console.error(err);
    }
});
